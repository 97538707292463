
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { AxiosRequestConfig } from "axios";
import JwtService from "@/core/services/JwtService";
import { event } from "vue-gtag";
import { ElMessage } from "element-plus";

interface Course {
  trimester: string;
  title: string;
  module: string;
  desc: string;
  subject: string;
  students: string[];
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

export default defineComponent({
  name: "add-course",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    widgetClasses: String,
    id: String,
  },
  setup() {
    const uploadPercentage = ref(0);

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);

    // eslint-disable-next-line
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const classID = route.params.id;

    const spec = ref<string>("0");

    ApiService.post("/teacher/classRommDetails", {
      query: {
        teacher: store.getters.currentUser._id,
      },
    })
      .then(({ data }) => {
        subjects.value = [];
        modules.value = [];
        const classroom = data.find(
          (el) => el.classRoom._id == route.params.id
        );
        if (classroom) {
          subjects.value = classroom.subjects;

          classroom.modulesSpecific.map((m) => {
            if (!modules.value.find((t) => t._id == m._id))
              modules.value.push(m);
          });
          classroom.modules.map((m) => {
            if (!modules.value.find((t) => t._id == m._id))
              modules.value.push(m);
          });
        }

        //remove duplicated
        subjects.value = subjects.value.filter(
          (subject, index) =>
            subjects.value.findIndex((s) => s._id == subject._id) === index &&
            !modules.value.find((m) =>
              m.subjects.find((ss) => ss._id == subject._id)
            ) &&
            subject.status !== "inactive"
        );
        getStudents([classroom.classRoom._id]);
      })
      .catch((e) => console.log(e));

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const course = ref<Course>({
      trimester: trimester,
      title: "",
      module: "",
      desc: "",
      subject: "",
      students: [],
    });

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
    });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);
    const maxSize = ref(0);
    const updateUploadFiles = (file, files) => {
      console.log("here");
      files.forEach((file) => {
        maxSize.value += file.raw.size;
      });
      maxSize.value = maxSize.value / 1024 / 1024;
      if (maxSize.value > 50) {
        ElMessage.error(t("course.fileSize"));
      }
      uploadFiles.value = files;
    };

    const submit = () => {
      if (maxSize.value > 50) {
        ElMessage.error(t("course.fileSize"));
        return;
      }
      const data = new FormData();

      for (const name in course.value) {
        data.append(name, course.value[name]);
      }

      uploadFiles.value.forEach((file) => {
        data.append("resources", file.raw, file.raw.name);
      });

      data.append("classrooms", classID as string);

      data.delete("students");
      if (course.value.students.length != 0) {
        course.value.students.forEach((st: string) => {
          data.append("students", st);
        });
      }

      data.append(
        "schoolarYear",
        localStorage.getItem("activeSchoolarYear") as string
      );

      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.put(
        "/lms/course",
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(({ data }) => {
          event("Add course", {
            event_category: "Course",
            event_label: "Classe profile",
            value: 1,
          });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.addedCourse"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            router.push(`/classe/${classID}/coursDetails/${data._id}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };
    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    const studentList = ref<any[]>([]);

    const getStudents = (classrooms: string[]) => {
      const sy = window.localStorage.getItem("activeSchoolarYear");
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
              classRoom: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data;
        })
        .catch((error) => console.log(error));
    };

    const clearStudents = () => {
      course.value.students = [];
    };

    return {
      t,
      classID,
      upload,
      subjects,
      modules,
      course,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      updateUploadFiles,
      handleExceed,
      customColorMethod,
      uploadPercentage,
      maxSize,
      studentList,
      clearStudents,
      spec,
    };
  },
});
